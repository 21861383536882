/* -- Material Design Table style -------------- */
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse:separate;
  border-radius:3px;
  -moz-border-radius:3px;
}
table > thead > tr,
table > tbody > tr,
table > tfoot > tr {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  text-align: left;
  padding: 0.15rem;
  padding-left: 0.5rem;
  vertical-align: top;
  font-size: 14px;
  border-top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
table > thead > tr > th {
  font-weight: 400;
  color: #757575;
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
table > tbody + tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table .no-border {
  border: 0;
}
table-condensed > thead > tr > th,
table-condensed > tbody > tr > th,
table-condensed > tfoot > tr > th,
table-condensed > thead > tr > td,
table-condensed > tbody > tr > td,
table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
table-bordered {
  border: 0;
}
table-bordered > thead > tr > th,
table-bordered > tbody > tr > th,
table-bordered > tfoot > tr > th,
table-bordered > thead > tr > td,
table-bordered > tbody > tr > td,
table-bordered > tfoot > tr > td {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
}
table-bordered > thead > tr > th,
table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
table-striped > tbody > tr:nth-child(odd) > td,
table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5;
}
table-hover > tbody > tr:hover > td,
table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 768px) {
  table-responsive-vertical > table {
    margin-bottom: 0;
    background-color: transparent;
  }
  table-responsive-vertical > table > thead,
  table-responsive-vertical > table > tfoot {
    display: none;
  }
  table-responsive-vertical > table > tbody {
    display: block;
  }
  table-responsive-vertical > table > tbody > tr {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-bottom: 1.6rem;
  }
  table-responsive-vertical > table > tbody > tr > td {
    background-color: #fff;
    display: block;
    vertical-align: middle;
    text-align: right;
  }
  table-responsive-vertical > table > tbody > tr > td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: inherit;
    font-weight: 400;
    color: #757575;
  }
  table-responsive-vertical.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  table-responsive-vertical.shadow-z-1 > table > tbody > tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  }
  table-responsive-vertical > table-bordered {
    border: 0;
  }
  table-responsive-vertical > table-bordered > tbody > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }
  table-responsive-vertical > table-bordered > tbody > tr > td:last-child {
    border-bottom: 0;
  }
  table-responsive-vertical > table-striped > tbody > tr > td,
  table-responsive-vertical > table-striped > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical > table-striped > tbody > tr > td:nth-child(odd) {
    background-color: #f5f5f5;
  }
  table-responsive-vertical > table-hover > tbody > tr:hover > td,
  table-responsive-vertical > table-hover > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical > table-hover > tbody > tr > td:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
table-stripedtable-mc-red > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-red > tbody > tr:nth-child(odd) > th {
  background-color: #fde0dc;
}
table-hovertable-mc-red > tbody > tr:hover > td,
table-hovertable-mc-red > tbody > tr:hover > th {
  background-color: #f9bdbb;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-red > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-red > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-red > tbody > tr > td:nth-child(odd) {
    background-color: #fde0dc;
  }
  table-responsive-vertical table-hovertable-mc-red > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-red > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-red > tbody > tr > td:hover {
    background-color: #f9bdbb;
  }
}
table-stripedtable-mc-pink > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-pink > tbody > tr:nth-child(odd) > th {
  background-color: #fce4ec;
}
table-hovertable-mc-pink > tbody > tr:hover > td,
table-hovertable-mc-pink > tbody > tr:hover > th {
  background-color: #f8bbd0;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-pink > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-pink > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-pink > tbody > tr > td:nth-child(odd) {
    background-color: #fce4ec;
  }
  table-responsive-vertical table-hovertable-mc-pink > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-pink > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-pink > tbody > tr > td:hover {
    background-color: #f8bbd0;
  }
}
table-stripedtable-mc-purple > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-purple > tbody > tr:nth-child(odd) > th {
  background-color: #f3e5f5;
}
table-hovertable-mc-purple > tbody > tr:hover > td,
table-hovertable-mc-purple > tbody > tr:hover > th {
  background-color: #e1bee7;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-purple > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-purple > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-purple > tbody > tr > td:nth-child(odd) {
    background-color: #f3e5f5;
  }
  table-responsive-vertical table-hovertable-mc-purple > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-purple > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-purple > tbody > tr > td:hover {
    background-color: #e1bee7;
  }
}
table-stripedtable-mc-deep-purple > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-deep-purple > tbody > tr:nth-child(odd) > th {
  background-color: #ede7f6;
}
table-hovertable-mc-deep-purple > tbody > tr:hover > td,
table-hovertable-mc-deep-purple > tbody > tr:hover > th {
  background-color: #d1c4e9;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-deep-purple > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-deep-purple > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-deep-purple > tbody > tr > td:nth-child(odd) {
    background-color: #ede7f6;
  }
  table-responsive-vertical table-hovertable-mc-deep-purple > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-deep-purple > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-deep-purple > tbody > tr > td:hover {
    background-color: #d1c4e9;
  }
}
table-stripedtable-mc-indigo > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-indigo > tbody > tr:nth-child(odd) > th {
  background-color: #e8eaf6;
}
table-hovertable-mc-indigo > tbody > tr:hover > td,
table-hovertable-mc-indigo > tbody > tr:hover > th {
  background-color: #c5cae9;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-indigo > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-indigo > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-indigo > tbody > tr > td:nth-child(odd) {
    background-color: #e8eaf6;
  }
  table-responsive-vertical table-hovertable-mc-indigo > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-indigo > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-indigo > tbody > tr > td:hover {
    background-color: #c5cae9;
  }
}
table-stripedtable-mc-blue > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e7e9fd;
}
table-hovertable-mc-blue > tbody > tr:hover > td,
table-hovertable-mc-blue > tbody > tr:hover > th {
  background-color: #d0d9ff;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-blue > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-blue > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-blue > tbody > tr > td:nth-child(odd) {
    background-color: #e7e9fd;
  }
  table-responsive-vertical table-hovertable-mc-blue > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-blue > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-blue > tbody > tr > td:hover {
    background-color: #d0d9ff;
  }
}
table-stripedtable-mc-light-blue > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-light-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e1f5fe;
}
table-hovertable-mc-light-blue > tbody > tr:hover > td,
table-hovertable-mc-light-blue > tbody > tr:hover > th {
  background-color: #b3e5fc;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-light-blue > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-light-blue > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-light-blue > tbody > tr > td:nth-child(odd) {
    background-color: #e1f5fe;
  }
  table-responsive-vertical table-hovertable-mc-light-blue > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-light-blue > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-light-blue > tbody > tr > td:hover {
    background-color: #b3e5fc;
  }
}
table-stripedtable-mc-cyan > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-cyan > tbody > tr:nth-child(odd) > th {
  background-color: #e0f7fa;
}
table-hovertable-mc-cyan > tbody > tr:hover > td,
table-hovertable-mc-cyan > tbody > tr:hover > th {
  background-color: #b2ebf2;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-cyan > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-cyan > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-cyan > tbody > tr > td:nth-child(odd) {
    background-color: #e0f7fa;
  }
  table-responsive-vertical table-hovertable-mc-cyan > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-cyan > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-cyan > tbody > tr > td:hover {
    background-color: #b2ebf2;
  }
}
table-stripedtable-mc-teal > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-teal > tbody > tr:nth-child(odd) > th {
  background-color: #e0f2f1;
}
table-hovertable-mc-teal > tbody > tr:hover > td,
table-hovertable-mc-teal > tbody > tr:hover > th {
  background-color: #b2dfdb;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-teal > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-teal > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-teal > tbody > tr > td:nth-child(odd) {
    background-color: #e0f2f1;
  }
  table-responsive-vertical table-hovertable-mc-teal > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-teal > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-teal > tbody > tr > td:hover {
    background-color: #b2dfdb;
  }
}
table-stripedtable-mc-green > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-green > tbody > tr:nth-child(odd) > th {
  background-color: #d0f8ce;
}
table-hovertable-mc-green > tbody > tr:hover > td,
table-hovertable-mc-green > tbody > tr:hover > th {
  background-color: #a3e9a4;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-green > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-green > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-green > tbody > tr > td:nth-child(odd) {
    background-color: #d0f8ce;
  }
  table-responsive-vertical table-hovertable-mc-green > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-green > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-green > tbody > tr > td:hover {
    background-color: #a3e9a4;
  }
}
table-stripedtable-mc-light-green > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-light-green > tbody > tr:nth-child(odd) > th {
  background-color: #f1f8e9;
}
table-hovertable-mc-light-green > tbody > tr:hover > td,
table-hovertable-mc-light-green > tbody > tr:hover > th {
  background-color: #dcedc8;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-light-green > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-light-green > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-light-green > tbody > tr > td:nth-child(odd) {
    background-color: #f1f8e9;
  }
  table-responsive-vertical table-hovertable-mc-light-green > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-light-green > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-light-green > tbody > tr > td:hover {
    background-color: #dcedc8;
  }
}
table-stripedtable-mc-lime > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-lime > tbody > tr:nth-child(odd) > th {
  background-color: #f9fbe7;
}
table-hovertable-mc-lime > tbody > tr:hover > td,
table-hovertable-mc-lime > tbody > tr:hover > th {
  background-color: #f0f4c3;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-lime > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-lime > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-lime > tbody > tr > td:nth-child(odd) {
    background-color: #f9fbe7;
  }
  table-responsive-vertical table-hovertable-mc-lime > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-lime > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-lime > tbody > tr > td:hover {
    background-color: #f0f4c3;
  }
}
table-stripedtable-mc-yellow > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-yellow > tbody > tr:nth-child(odd) > th {
  background-color: #fffde7;
}
table-hovertable-mc-yellow > tbody > tr:hover > td,
table-hovertable-mc-yellow > tbody > tr:hover > th {
  background-color: #fff9c4;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-yellow > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-yellow > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-yellow > tbody > tr > td:nth-child(odd) {
    background-color: #fffde7;
  }
  table-responsive-vertical table-hovertable-mc-yellow > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-yellow > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-yellow > tbody > tr > td:hover {
    background-color: #fff9c4;
  }
}
table-stripedtable-mc-amber > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-amber > tbody > tr:nth-child(odd) > th {
  background-color: #fff8e1;
}
table-hovertable-mc-amber > tbody > tr:hover > td,
table-hovertable-mc-amber > tbody > tr:hover > th {
  background-color: #ffecb3;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-amber > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-amber > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-amber > tbody > tr > td:nth-child(odd) {
    background-color: #fff8e1;
  }
  table-responsive-vertical table-hovertable-mc-amber > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-amber > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-amber > tbody > tr > td:hover {
    background-color: #ffecb3;
  }
}
table-stripedtable-mc-orange > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fff3e0;
}
table-hovertable-mc-orange > tbody > tr:hover > td,
table-hovertable-mc-orange > tbody > tr:hover > th {
  background-color: #ffe0b2;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-orange > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-orange > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-orange > tbody > tr > td:nth-child(odd) {
    background-color: #fff3e0;
  }
  table-responsive-vertical table-hovertable-mc-orange > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-orange > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-orange > tbody > tr > td:hover {
    background-color: #ffe0b2;
  }
}
table-stripedtable-mc-deep-orange > tbody > tr:nth-child(odd) > td,
table-stripedtable-mc-deep-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fbe9e7;
}
table-hovertable-mc-deep-orange > tbody > tr:hover > td,
table-hovertable-mc-deep-orange > tbody > tr:hover > th {
  background-color: #ffccbc;
}
@media screen and (max-width: 767px) {
  table-responsive-vertical table-stripedtable-mc-deep-orange > tbody > tr > td,
  table-responsive-vertical table-stripedtable-mc-deep-orange > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table-responsive-vertical table-stripedtable-mc-deep-orange > tbody > tr > td:nth-child(odd) {
    background-color: #fbe9e7;
  }
  table-responsive-vertical table-hovertable-mc-deep-orange > tbody > tr:hover > td,
  table-responsive-vertical table-hovertable-mc-deep-orange > tbody > tr:hover {
    background-color: #fff;
  }
  table-responsive-vertical table-hovertable-mc-deep-orange > tbody > tr > td:hover {
    background-color: #ffccbc;
  }
}

table td {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

table td:first-child {
    border-left: none;
}
